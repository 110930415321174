@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.App {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p {
  font-family: "Roboto", sans-serif;
}

.clickable:hover {
  cursor: pointer;
}

.lobby {
  margin-top: 70px;
  .mainLogo img {
    height: 400px;
  }
  form {
    width: 350px;
    margin: auto;
    .inputContainer {
      width: 100%;
      float: left;
      input {
        font-size: 24px;
        padding: 10px 20px;
        font-weight: bold;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }

    .numberOfPlayersContainer {
      float: left;
      margin-top: 10px;
      margin-left: 5px;
      label {
        width: 100%;
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: bold;
      }
      select {
        font-size: 18px;
        padding: 10px 20px;
        margin-top: 3px;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
    button {
      float: none;
      margin-top: 32px;
      margin-left: -20px;
      width: 220px;
    }
  }

  h2 {
    font-size: 12px;
  }

  .lobbyTable {
    margin-top: 50px;
    width: 800px;
    th {
      padding: 10px;
      border-bottom: solid 1px #ccc;
    }
    td {
      padding: 10px;
    }
    .date {
      font-size: 12px;
    }
    .delete {
      img {
        width: 50px;
      }
    }
    .delete:hover {
      cursor: pointer;
      opacity: 0.5;
    }
    .button {
      float: none;
      margin: auto;
    }
  }
}

.characterRed {
  color: red;
}

.characterGreen {
  color: green;
}

.characterBlue {
  color: blue;
}

.characterYellow {
  color: orange;
}

.nav-tabs {
  width: 100%;
}

.tab-content {
  width: 100%;
}

.powerplants {
  padding-left: 30px;
  border-left: 5px solid #eee;
  float: left;
  width: 950px;
  h2 {
    font-size: 14px;
  }
  .market,
  .pps {
    width: 850px;
    float: left;
    margin-bottom: 20px;
  }
  .futureMarket {
    opacity: 0.5;
  }
}

.button {
  padding: 15px 20px;
  float: left;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.button:hover {
  cursor: pointer;
}
.primary {
  background-color: #4bc7f2;
  color: #fff;
  border: 3px solid #fff;
  margin-right: 20px;
}
.primary:hover {
  background-color: #1e5061;
}
.secondary {
}

.biddingArea {
  padding: 30px;
  background-color: #4bc7f2;
  border-radius: 10px;
  color: #fff;
  float: left;
  width: 400px;
  text-align: center;
  h2 {
    font-size: 18px;
  }
  .choosePlant {
    width: 200px;
    height: 200px;
    margin: auto;
    border: 5px dashed #fff;
    border-radius: 5px;
    padding: 20px;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  .plant {
    margin: auto;
    float: none;
  }
  .selectedPp {
    margin-bottom: 20px;
  }
  .price {
    font-size: 50px;
    margin-bottom: 10px;
    input {
      margin-left: 10px;
      margin-bottom: 20px;
      padding: 10px;
      font-size: 50px;
      width: 150px;
      background-color: #fff;
      border: solid 2px #fff;
      color: #000;
      //border-radius: 25px;
    }
  }
  .primary {
    background-color: #fff;
    color: #4bc7f2;
  }
  .primary:hover {
    background-color: #1e5061;
    color: #fff;
    border-color: #1e5061;
  }
  .secondary {
    border: 1px solid #fff;
  }
  .secondary:hover {
    border-color: #1e5061;
    color: #1e1e1e;
  }
  .ctas {
    margin: auto;
    width: 325px;
    margin: auto;
  }
}

.plant:hover.clickable {
  border-color: #d3a527;
}

.plant {
  background-size: cover;
  width: 200px;
  height: 200px;
  border: solid 3px #fec830;
  padding: 0px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  float: left;
  position: relative;
  text-align: left;
  color: #000;
  .topbar {
    background-color: #fec830;
    border-bottom: 1px solid #ccc;
    height: 50px;
    padding: 10px;
    .cost {
      font-size: 20px;
      font-weight: bold;
      float: left;
    }
    .resourceContainer {
      float: right;
      img {
        height: 30px;
        margin-right: 5px;
        float: right;
      }
    }
  }
  .foodName {
    width: 150px;
    font-size: 18px;
    font-weight: 900;
    margin-left: 10px;
    margin-top: 5px;
    line-height: 24px;
    position: absolute;
    bottom: 22px;
    span {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  .partner img {
    height: 30px;
    position: absolute;
    bottom: -15px;
    right: 5px;
    background-color: #fff;
    border: 3px solid #fec830;
  }
  .locations {
    background-color: #fec830;
    border-radius: 55px;
    width: 60px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    img {
      height: 25px;
      margin-left: -5px;
      margin-right: -4px;
      margin-top: -3px;
    }
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    bottom: -15px;
    left: 5px;
  }
}

.resources {
  width: 100%;
  .resourcesTable {
    width: 500px !important;
  }
  .resource {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    .coal,
    .oil,
    .garbage,
    .uranium,
    .blank {
      float: left;
      background-size: cover;
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
    .blank {
      background-color: #eee;
      margin-top: 5px;
    }
    .coal {
      background-image: url("./assets/images/icon-meat.png");
    }
    .oil {
      background-image: url("./assets/images/icon-choi.png");
    }
    .garbage {
      background-image: url("./assets/images/icon-tea.png");
    }
    .uranium {
      background-image: url("./assets/images/icon-tofu.png");
    }
    .price {
      background-color: #fff;
      border-radius: 100px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      border: 2px solid #000;
      margin-top: 20px;
    }

    h2 {
      font-size: 16px;
    }
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    button {
      font-size: 16px;
    }
  }
  .remainingSupply {
    float: right;
    margin-top: -5px;
    margin-left: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 10px;
    }
  }
  button {
    background-color: #4bc7f2;
    border-radius: 5px;
    border: #fff;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    float: left;
    margin-right: 20px;
    padding: 10px 20px;
  }
  button:hover {
    background-color: #1e5061;
  }
  .waiting {
    font-size: 24px;
    font-weight: bold;
    border: dashed 1px #ccc;
    padding: 20px;
    text-align: center;
    color: #333;
  }
}

.bidOrder,
.buyOrder {
  margin-right: 50px;
  margin-bottom: 10px;
  float: left;
  .player,
  .turnOrder {
    float: left;
    margin-right: 10px;
  }
  .label {
    float: left;
    margin-right: 10px;
    font-weight: bold;
  }
}

.gameWindow {
  margin-top: 60px;
  .mainArea {
    padding: 20px;
    margin-left: 85px;
    .nav-tabs {
      border: none;
      margin-bottom: 30px;
      .nav-link {
        padding: 5px 0px;
        border: none;
        font-size: 25px;
        font-weight: 900;
        margin-right: 30px;
        border-radius: 0px;
      }
      .active {
        border-bottom: 5px solid #000;
        color: #000;
      }
      a {
        color: #ccc;
      }
      .attn {
        border-bottom: 5px dashed #ef4938;
      }
    }
  }
}

.playerBar {
  position: fixed;
  top: 0;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  padding: 10px 10px 20px 10px;
  //background-image: url("./assets/images/playerbar.png");
  background-color: #fff;
  background-size: cover;
  background-repeat: repeat-x;
  z-index: 99;
  //border-bottom: 3px solid #ccc;
  color: #222;
  .gameInfo {
    float: right;
    .stage,
    .round,
    .phase,
    .activePlayer {
      float: left;
      margin-right: 10px;
      background-color: #eee;
      padding: 5px 20px;
      border-radius: 20px;
    }
    .activePlayer {
      margin-top: 2px;
    }
    font-size: 14px;
    font-weight: bold;
    img {
      height: 25px;
      margin-right: 5px;
    }
    .phase {
      margin-top: 2px;
      font-weight: normal;
    }
  }
  .inventory {
    float: left;
    margin: 0px 0px 0px 10px;
    .name,
    .character,
    .cash,
    .phase,
    .activePlayer,
    .round,
    .coal,
    .oil,
    .hybrid,
    .garbage,
    .uranium,
    .location {
      float: left;
      margin-right: 20px;
      background-color: #eee;
      padding: 5px 20px;
      border-radius: 20px;
    }
    .breakBorder {
      float: left;
      width: 2px;
      height: 35px;
      margin-right: 20px;
      background-color: #ccc;
    }
    .name {
      font-weight: bold;
    }
    img {
      height: 25px;
      margin-right: 5px;
    }
    font-weight: bold;
    font-size: 14px;
  }

  .hybrid {
    opacity: 0.5;
  }
}
.belowMenu {
  z-index: 0;
}

.aboveMenu {
  z-index: 1000;
}
.stepTransition {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .infoBox {
    width: 500px;
    background-color: #fff;
    border-radius: 5px;
    padding: 50px;
    margin: auto;
    text-align: center;
    .stageRound {
      width: 100%;
      float: left;
      margin: auto;
      margin-bottom: 10px;
      text-align: center;
      .stageRoundWrapper {
        display: inline-block;
        .stage,
        .round {
          float: left;
          margin-right: 10px;
          background-color: #eee;
          padding: 5px 20px;
          border-radius: 20px;
          img {
            width: 15px;
          }
        }
      }
    }

    .gif {
      video {
        max-height: 300px;
        max-width: 400px;
        border-radius: 5px;
        margin-bottom: 15px;
      }
    }
    h2 {
      font-size: 18px;
    }
    .button {
      margin: auto;
      display: inline-block;
      margin-top: 10px;
      float: none;
    }
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.playerList {
  position: fixed;
  left: 20px;
  top: 70px;
  text-align: center;
  //width: 1000px;
  .gameMarker {
    width: 70px;
    float: left;
    margin: auto;
    margin-top: 30px;
    .marker {
      margin-bottom: 20px;
      .title {
        font-size: 12px;
        font-weight: bold;
        opacity: 0.5;
      }
      .stepNumber {
        opacity: 0.5;
        border: 2px solid #333;
        border-radius: 30px;
        font-weight: bold;
        margin-top: 5px;
        img {
          width: 30px;
          margin-right: -5px;
        }
      }
    }
  }
  .playerOrderIcons:hover {
    cursor: pointer;
  }
  .playerOrderIcons {
    .playerDetails.Yellow {
      border: solid 3px #f3d248;
      background-color: #f3d248;
    }
    .playerDetails.Blue {
      border: solid 3px #4bc7f2;
      background-color: #4bc7f2;
    }
    .playerDetails.Red {
      border: solid 3px #ef4938;
      background-color: #ef4938;
    }
    .playerDetails.Green {
      border: solid 3px #35b36d;
      background-color: #35b36d;
    }
    .playerDetails.Purple {
      border: solid 3px #baaad4;
      background-color: #baaad4;
    }
    .playerDetails.Orange {
      border: solid 3px #f77e1b;
      background-color: #f77e1b;
    }
    .playerDetails.Pink {
      border: solid 3px #fabbcc;
      background-color: #fabbcc;
    }
    .playerProfile {
      width: 100%;
      float: left;
      .smallLocations {
        position: absolute;
        top: 55px;
        left: -5px;
        background-color: #eee;
        padding: 5px;
        border-radius: 100px;
        width: 25px;
        height: 25px;
        font-size: 12px;
        font-weight: bold;
      }
      .playerDetails {
        height: 60px;
        width: 740px;
        z-index: -1;
        float: left;
        margin-left: -30px;
        margin-top: 10px;
        .resourcesDetails {
          float: left;
          width: 100%;
          border: none !important;
          padding-left: 40px;
          padding-top: 12px;
          .resource {
            float: left;
            margin-right: 10px;
            border: solid 1px #eee;
            border-radius: 5px;
            padding: 2px 6px;
            background-color: #fff;
            font-weight: bold;
          }
          img {
            width: 15px;
          }
        }
      }
    }
    .character {
      position: relative;
      left: 0px;
      width: 70px;
      float: left;
      .icon {
        width: 65px;
        height: 65px;
        border-radius: 65px;
        border: solid 2px #fff;
        background-size: cover;
        margin: 10px auto 5px auto;
      }

      .iconBlue {
        background-image: url("./assets/images/iconBlue.jpeg");
      }
      .iconGreen {
        background-image: url("./assets/images/iconGreen.jpeg");
      }
      .iconOrange {
        background-image: url("./assets/images/iconOrange.jpeg");
      }
      .iconPink {
        background-image: url("./assets/images/iconPink.jpeg");
      }
      .iconPurple {
        background-image: url("./assets/images/iconPurple.jpeg");
      }
      .iconRed {
        background-image: url("./assets/images/iconRed.jpeg");
      }
      .iconYellow {
        background-image: url("./assets/images/iconYellow.jpeg");
      }
      .playerName {
        width: 65px;
        margin: auto;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
      }
    }
    .self {
      .playerName {
        width: 80px;
      }
      .icon {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}

.plantMini {
  width: 110px;
  height: 60px;
  padding: 5px 10px;
  border: solid 2px #000;
  float: left;
  margin-left: 20px;
  background-color: #f3d248;
  border-radius: 5px;
  .topbar {
    float: left;
    width: 100%;
    text-align: center;
    .cost {
      float: left;
      margin-right: 5px;
      font-size: 14px;
      font-weight: bold;
    }
    .resourceContainer {
      float: left;
      img.resourceIcons {
        margin-right: 3px;
        width: 15px !important;
        margin-top: -5px;
      }
    }
  }
  .locations {
    font-size: 18px;
    font-weight: bold;
    background-color: #fff;
    display: inline-block;
    border-radius: 100px;
    padding: 0px 7px;
    border: 2px solid #333;
    margin-top: 5px;
    img {
      margin-right: -4px !important;
    }
  }
}

.mapContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.mainMapImage {
  //width: 3023px;
  //height: 1956px;
  display: flex;
}

.mainMap {
  position: relative;
  //width: 3023px;
  //height: 1956px;
}

.disabledCity {
  opacity: 0.3;
}
.cityContainer {
  position: absolute;
  background-image: url("./assets/images/cityIcon.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 200px;
  height: 150px;
  z-index: 20;
}

.cityContainer:hover {
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff;
  border-radius: 100px;
  cursor: pointer;
}

.disabled:hover {
  box-shadow: none;
  cursor: default;
  border-radius: 0px;
}

.citySelected {
  box-shadow: 0 0 10px #f3d248, 0 0 20px #f3d248, 0 0 30px #f3d248;
  border-radius: 20px;
}

.citySelected:hover {
  box-shadow: 0 0 10px #f3d248, 0 0 20px #f3d248, 0 0 30px #f3d248;
}

.cityName {
  font-size: 20px;
  margin-top: 90px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  border: 5px solid #ef4938;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  .chinese {
    font-size: 14px;
    margin-top: 0px !important;
    width: 100%;
  }
}

.connections {
  position: absolute;
  z-index: 5;
}

.connectionPipesContainer {
  position: relative;
  width: 3023px;
  height: 1956px;
}

.connectionPipe {
  height: 20px;
  //background-image: url("./assets/images/copperPipe.jpg");
  //background-repeat: repeat-x;
  // background-size: contain;
  background-color: #ffc5c5;
  position: absolute;
  transform-origin: top left;
  margin-top: 70px;
  margin-left: 80px;
}

.connectionCost {
  background-color: #fff;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  margin-top: 50px;
  margin-left: 42px;
  z-index: 100;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  border: solid 5px #333;
  text-align: center;
}

.homes {
  position: absolute;
  margin-top: 30px;
  .houseRed,
  .houseGreen,
  .houseOrange,
  .housePink,
  .housePurple,
  .houseRed,
  .houseYellow,
  .houseBlue {
    width: 60px;
    height: 60px;
    background-size: cover;
    //border: solid 1px #000;
    padding: 0px;
    float: left;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.8);
    margin-right: -20px;
  }
  .houseBlue {
    background-image: url("./assets/images/iconBlue.jpeg");
  }
  .houseGreen {
    background-image: url("./assets/images/iconGreen.jpeg");
  }
  .houseOrange {
    background-image: url("./assets/images/iconOrange.jpeg");
  }
  .housePink {
    background-image: url("./assets/images/iconPink.jpeg");
  }
  .housePurple {
    background-image: url("./assets/images/iconPurple.jpeg");
  }
  .houseRed {
    background-image: url("./assets/images/iconRed.jpeg");
  }
  .houseYellow {
    background-image: url("./assets/images/iconYellow.jpeg");
  }
}

.buyBox {
  text-align: center;
  position: absolute;
  z-index: 99;
  right: 0;
  margin: 30px 30px 0px 0px;
  .top {
    width: 200px;
    //height: 150px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    .nameEnglish {
      font-size: 16px;
    }
    .nameChinese {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .selectedCity {
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
      text-align: center;
    }
    .buyMsg {
      font-size: 14px;
    }
    .cost {
      font-size: 32px;
      font-weight: bold;
      background-color: #35b36d;
      color: #fff;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
  .bottom {
    text-align: center;
    button {
      background-color: #4bc7f2;
      border-radius: 5px;
      border: #fff;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      padding: 10px 20px;
      width: 100%;
    }
    button:hover {
      background-color: #1e5061;
    }
    .waiting {
      background-color: #fff;
      padding: 20px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
.revenueContainer {
  margin-left: 30px;

  .profitTable {
    margin-bottom: 10px;
  }
}

table {
  margin-bottom: 10px;
  th,
  td {
    padding: 5px 10px;
    border: 1px solid #ccc;
  }
}

.bureaucracy {
  .bureaucracyPlant {
    width: 200px;
    margin-right: 10px;
    float: left;
    button {
      float: left;
      background-color: #4bc7f2;
      border-radius: 5px;
      border: #fff;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      padding: 10px 20px;
      width: 100%;
      margin-bottom: 5px;
    }
    button:hover {
      background-color: #1e5061;
    }
    button.destroy {
      width: 100px;
      margin-top: 20px;
      color: #ef4938 !important;
      background: none;
      border: 2px solid #ef4938;
      padding: 2px 5px;
      font-size: 10px;
    }
    button.destroy:hover {
      background-color: #ef4938 !important;
      color: #fff !important;
    }
  }
  .report {
    width: 370px;
    border: 3px solid #35b36d;
    float: left;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    color: #000;
    .info {
      text-align: center;
      padding: 10px 0px;
      float: left;
      width: 300px;
      color: #000;
      .served {
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        img {
          height: 60px;
          margin-bottom: -10px;
        }
        .details {
          font-size: 12px;
        }
        .number {
          margin-top: 0px;
          font-size: 34px;
        }
        .potential {
          font-size: 12px;
        }
      }

      .resources {
        width: 240px;
        margin: auto;
        display: block;
        float: none;
        margin-left: 40px;
        .resource {
          float: left;
          width: 100px;
          margin-right: 20px;
          background-color: #fff;
          padding: 5px 20px;
          border-radius: 20px;
          margin-bottom: 10px;
          img {
            height: 25px;
            margin-right: 5px;
          }
          font-weight: bold;
        }
      }
      .expected {
        color: #35b36d;
        font-size: 12px;
      }
      .profit {
        color: #35b36d;
        font-size: 50px;
        font-weight: bold;
      }
    }
  }
  .burButton {
    float: left;
    background-color: #4bc7f2;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    padding: 20px;
    border-radius: 5px;
  }
  .burButton:hover {
    cursor: pointer;
    background-color: #1e5061;
  }
}

.deusEx {
  margin-top: 100px;
  .manualAdjust {
    font-size: 10px;
  }
  button {
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 5px;
  }
  button:hover {
    background-color: #333;
    color: #fff;
  }
}
.setup {
  width: 1000px;
  margin: auto;
  text-align: left;
  margin-top: 50px;
  button {
    background-color: #4bc7f2;
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    margin-left: 15px;
    height: 50px;
  }
  button:hover {
    background-color: #1e5061;
    cursor: pointer;
  }
  h1 {
    font-size: 14px;
  }
  .currentPlayers {
    text-align: center;
    border: 1px solid #eee;
    padding: 20px;
    float: left;
    width: 100%;
    h2 {
      font-size: 16px;
    }
    .totalJoined {
      width: 100%;
      float: left;
    }
    .setupPlayers {
      margin-bottom: 20px;
      margin-top: 20px;
      display: inline-block;
      .player {
        text-align: center;
        width: 150px;
        float: left;
        .icon {
          width: 50px;
          height: 50px;
          background-size: cover;
          margin: auto;
          border-radius: 100px;
        }
        .playerName {
          font-size: 18px;
          font-weight: bold;
          margin-top: 5px;
        }
        button {
          height: 35px;
          padding: 5px 10px;
          display: inline-block;
          margin: 0px;
          margin-top: 15px;
        }
        .iconBlue {
          background-image: url("./assets/images/iconBlue.jpeg");
        }
        .iconGreen {
          background-image: url("./assets/images/iconGreen.jpeg");
        }
        .iconOrange {
          background-image: url("./assets/images/iconOrange.jpeg");
        }
        .iconPink {
          background-image: url("./assets/images/iconPink.jpeg");
        }
        .iconPurple {
          background-image: url("./assets/images/iconPurple.jpeg");
        }
        .iconRed {
          background-image: url("./assets/images/iconRed.jpeg");
        }
        .iconYellow {
          background-image: url("./assets/images/iconYellow.jpeg");
        }
      }
    }
  }
  .addYourself {
    width: 400px;
    margin: auto;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
    h2 {
      font-size: 14px;
    }
    input {
      font-size: 28px;
      padding: 10px 20px;
      font-weight: bold;
    }
    .selectColor {
      margin-top: 10px;
      select {
        font-size: 18px;
        padding: 10px 20px;
        margin-top: 3px;
        height: 50px;
      }
    }
  }
}

.winnerBox {
  position: fixed;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  z-index: 99999;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .scoreBox {
    //
    color: #fff;
    position: fixed;
    z-index: 9999;
    width: 700px;
    left: 40px;
    top: 250px;
    padding: 40px;
    font-size: 24px;
    font-weight: bold;
    .place {
      background-color: rgba(0, 0, 0, 0.4);
      border: 2px solid #fff;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 20px;
    }
    .secondPlace {
      font-size: 28px;
    }
    .firstPlace {
      font-size: 36px;
      background-color: #f3d248;
      color: #000;
    }
  }
}
