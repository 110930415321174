.plant1 {
  background-image: url("./assets/images/food-cucumbers.jpeg");
}
.plant2 {
  background-image: url("./assets/images/food-shaomai.jpg");
}
.plant3 {
  background-image: url("./assets/images/food-oyster-omelette.jpg");
}

.plant4 {
  background-image: url("./assets/images/food-black-milk-tea.png");
}
.plant5 {
  background-image: url("./assets/images/food-scallion-pancake.jpeg");
}

.plant6 {
  background-image: url("./assets/images/food-fried-chicken.jpg");
}

.plant7 {
  background-image: url("./assets/images/food-wheel-cake.jpg");
}

.plant8 {
  background-image: url("./assets/images/food-sausage-wrap.jpg");
}

.plant9 {
  background-image: url("./assets/images/food-soy-milk.jpeg");
}

.plant10 {
  background-image: url("./assets/images/food-guabao.jpg");
}
.plant11 {
  background-image: url("./assets/images/food-pinball.jpg");
}
.plant12 {
  background-image: url("./assets/images/food-jasmine-boba.jpg");
}

.plant13 {
  background-image: url("./assets/images/food-lu-rou-fan.jpg");
}

.plant14 {
  background-image: url("./assets/images/food-sweet-potato-balls.jpg");
}

.plant15 {
  background-image: url("./assets/images/food-stinky-tofu.jpg");
}

.plant16 {
  background-image: url("./assets/images/food-hippies.jpg");
}
.plant17 {
  background-image: url("./assets/images/food-matcha-tea.jpg");
}
.plant18 {
  background-image: url("./assets/images/food-pig-blood.jpg");
}
.plant19 {
  background-image: url("./assets/images/food-soup-dumplings.jpg");
}
.plant20 {
  background-image: url("./assets/images/food-drummer.jpg");
}
.plant21 {
  background-image: url("./assets/images/food-tofu-pudding.jpg");
}
.plant22 {
  background-image: url("./assets/images/food-taro.jpg");
}
.plant23 {
  background-image: url("./assets/images/food-pepper.jpg");
}
.plant24 {
  background-image: url("./assets/images/food-taroballs.jpg");
}
.plant25 {
  background-image: url("./assets/images/food-fishing.jpg");
}
.plant26 {
  background-image: url("./assets/images/food-tofu-pudding-2.jpg");
}
.plant27 {
  background-image: url("./assets/images/food-hotpot.jpg");
}
.plant28 {
  background-image: url("./assets/images/food-sago.jpg");
}
.plant29 {
  background-image: url("./assets/images/food-steak.jpg");
}
.plant30 {
  background-image: url("./assets/images/food-burger.jpg");
}
.plant31 {
  background-image: url("./assets/images/food-shrimp-fishing.jpg");
}
.plant32 {
  background-image: url("./assets/images/food-tofu-egg.jpg");
}
.plant33 {
  background-image: url("./assets/images/food-steamed-egg.jpg");
}
.plant34 {
  background-image: url("./assets/images/food-seafood.jpg");
}
.plant35 {
  background-image: url("./assets/images/food-gaming.jpg");
}
.plant36 {
  background-image: url("./assets/images/food-tea-ceremony.jpg");
}
.plant37 {
  background-image: url("./assets/images/food-soymilk-fresh.jpg");
}
.plant38 {
  background-image: url("./assets/images/food-veggie-buffet.jpg");
}
.plant39 {
  background-image: url("./assets/images/food-duck.jpg");
}
.plant40 {
  background-image: url("./assets/images/food-claw-machines.jpg");
}
.plant41 {
  background-image: url("./assets/images/food-beef-noodle-soup.jpg");
}
.plant42 {
  background-image: url("./assets/images/food-shenyun.jpg");
}
